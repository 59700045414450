import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Auth',
    component: () => import('../components/auth/Auth.vue')
  },
  {
    path: '/clientes',
    name: 'Client',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/client/Client.vue')
  },
  {
    path: '/chave/gerar',
    name: 'GenerateKey',
    component: () => import('../components/key/GenerateKey.vue')
  },
  {
    path: '/chave/listar',
    name: 'ListingKey',
    component: () => import('../components/key/ListingKey.vue')
  },
  {
    path: '/cliente/adicionar',
    name: 'ClientAdd',
    component: () => import('../components/client/ClientForm.vue')
  },
  {
    path: '/cliente/:id',
    name: 'ClientEdit',
    component: () => import('../components/client/ClientForm.vue')
  },
  {
    path: '/usuarios/',
    name: 'User',
    component: () => import('../components/user/User.vue')
  },
  {
    path: '/usuario/adicionar',
    name: 'UserAdd',
    component: () => import('../components/user/UserForm.vue')
  },
  {
    path: '/usuario/:id',
    name: 'UserEdit',
    component: () => import('../components/user/UserForm.vue')
  },
  {
    path: '/revendas/',
    name: 'Resale',
    component: () => import('../components/resale/Resale.vue')
  },
  {
    path: '/revenda/adicionar',
    name: 'ResaleForm',
    component: () => import('../components/resale/ResaleForm.vue')
  },
  {
    path: '/revenda/:id',
    name: 'ResaleEdit',
    component: () => import('../components/resale/ResaleForm.vue')
  },
  {
    path: '/chave-light/gerar',
    name: 'GenerateKeyLight',
    component: () => import('../components/key/GenerateKeyLight.vue')
  },
  {
    path: '/chave-clearbank/gerar',
    name: 'GenerateKeyClearbank',
    component: () => import('../components/key/GenerateKeyClearbank.vue')
  },
  {
    path: '/chave-big/gerar',
    name: 'GenerateKeyBig',
    component: () => import('../components/key/GenerateKeyBig.vue')
  },
  {
    path: '/produtos/',
    name: 'Product',
    component: () => import('../components/product/Product.vue')
  },
  {
    path: '/produto/adicionar',
    name: 'ProductForm',
    component: () => import('../components/product/ProductForm.vue')
  },
  {
    path: '/produto/:id',
    name: 'ProductEdit',
    component: () => import('../components/product/ProductForm.vue')
  },
  {
    path: '/cliente/:id/contrato',
    name: 'ContractClient',
    component: () => import('../components/client/ContractClient.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
