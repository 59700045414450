<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="3" lg="2">
          <v-text-field
            :counter="14"
            maxlength="14"
            v-model="client.cnpj"
            :rules="cnpjRules"
            append-icon="mdi-arrow-right-bold"
            @click:append="appendIconCallback"
            label="CNPJ"
            :disabled="!disabled"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5" lg="4">
          <v-text-field
            maxlength="80"
            v-model="client.name"
            :rules="generalRules"
            label="Razão social"
            :disabled="disabled"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            maxlength="30"
            class="input-uppercase"
            v-model="client.address"
            :rules="generalRules"
            label="Logradouro"
            :disabled="disabled"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            maxlength="5"
            v-model="client.number"
            :rules="generalRules"
            label="Numero"
            :disabled="disabled"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            maxlength="30"
            class="input-uppercase"
            v-model="client.district"
            :rules="generalRules"
            label="Bairro"
            :disabled="disabled"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" xl="2">
          <v-text-field
            maxlength="30"
            class="input-uppercase"
            v-model="client.city"
            :rules="generalRules"
            label="Cidade"
            :disabled="disabled"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="1">
          <v-text-field
            maxlength="2"
            class="input-uppercase"
            v-model="client.state"
            :rules="generalRules"
            label="Estado"
            :disabled="disabled"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5" xl="3">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="disabled"
                :value="computedDateFormattedMomentjs"
                label="Expiração PDV"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              v-model="client.expirationAt"
              :allowed-dates="allowedDates"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="5" xl="3">
          <v-menu
            v-model="menuPr"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="disabled"
                :value="computedDatePrFormattedMomentjs"
                label="Expiração PR"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              v-model="client.expirationPrAt"
              :allowed-dates="allowedDates"
              @input="menuPr = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="5" xl="3">
          <v-menu
            v-model="menuCl"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="disabled"
                :value="computedDateClFormattedMomentjs"
                label="Expiração Clearbank"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              v-model="client.expirationClearAt"
              :allowed-dates="allowedDates"
              @input="menuCl = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4" xl="3">
          <v-select
            :items="items"
            v-model="client.resaleId"
            label="Revenda"
            item-text="name"
            item-value="id"
            :disabled="disabled"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" xl="3">
          <v-select
            :items="itemsModality"
            v-model="client.modality"
            label="Modalidade"
            item-text="label"
            item-value="value"
            :disabled="disabled"
          ></v-select>
        </v-col>
        
        <v-col cols="12" md="1" xl="2">
          <v-switch
            style="margin: 0; padding: 0"
            v-model="client.blocked"
            color="red"
            label="Bloqueado?"
            hide-details
            :disabled="disabled"
          ></v-switch>
          <v-switch
            style="margin: 0; padding: 0"
            v-model="client.test"
            label="Teste?"
            hide-details
            :disabled="disabled"
          ></v-switch>
        </v-col>
        <v-col cols="12">
          <v-textarea
            rows="3"
            :no-resize="true"
            v-model="client.observation"
            label="Observação"
            :disabled="disabled"
          ></v-textarea>
        </v-col>
      </v-row>
      <div v-if="$vuetify.breakpoint.mobile" class="mt-3">
        <v-btn
          :disabled="!valid || disabled"
          color="success"
          class="mr-4"
          @click="save"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn color="warning" class="mr-4" @click="reset"
          ><v-icon>mdi-broom</v-icon></v-btn
        >
        <v-btn color="error" to="/clientes"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div v-if="!$vuetify.breakpoint.mobile" class="mt-3">
        <v-btn
          :disabled="!valid || disabled"
          color="success"
          class="mr-4"
          @click="save"
          >Salvar</v-btn
        >
        <v-btn color="warning" class="mr-4" @click="reset">Limpar</v-btn>
        <v-btn color="error" to="/clientes">Cancelar</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { baseApiUrl } from "@/global";
import jsonp from "jsonp";
moment.locale("pt-br");
export default {
  name: "ClientForm",
  data: () => ({
    valid: false,
    disabled: true,
    menu: false,
    menuPr: false,
    menuCl: false,
    client: {},
    generalRules: [(v) => !!v || "Campo obrigatório."],
    cnpjRules: [
      (v) => !!v || "Campo obrigatório.",
      (v) => (v && v.length == 14) || "Necessário 14 caracteres",
    ],
    items: [],
    itemsModality: [
        { label: "MENSALISTA", value: "monthly" },
        { label: "ANUAL", value: "yearly" },
      ],
  }),
  computed: {
    computedDateFormattedMomentjs() {
      return this.client.expirationAt
        ? moment(this.client.expirationAt).format("dddd, ll")
        : "";
    },
    dateFormatted() {
      return moment(this.client.expirationAt).format();
    },
    computedDatePrFormattedMomentjs() {
      return this.client.expirationPrAt
        ? moment(this.client.expirationPrAt).format("dddd, ll")
        : "";
    },
    datePrFormatted() {
      return moment(this.client.expirationPrAt).format();
    },
    computedDateClFormattedMomentjs() {
      return this.client.expirationClearAt
        ? moment(this.client.expirationClearAt).format("dddd, ll")
        : "";
    },
    dateClFormatted() {
      return moment(this.client.expirationClearAt).format();
    },
    statusClient() {
      return this.client.blocked ? "BLOQUEADO" : "DESBLOQUEADO";
    },
  },
  methods: {
    appendIconCallback() {
      jsonp(
        `https://www.receitaws.com.br/v1/cnpj/${this.client.cnpj}`,
        null,
        (err, info) => {
          if (err) {
            console.erro(err.mensagem);
          } else {
            const cnpj = this.client.cnpj;
            if (info.status === "OK") {
              this.client = {
                name: info.nome,
                cnpj,
                address: info.logradouro,
                number: info.numero,
                district: info.bairro,
                city: info.municipio,
                state: info.uf,
              };
              this.disabled = false;
            } else {
              alert(info.message);
              this.disabled = false;
              this.valid = false;
            }
          }
        }
      );
    },
    allowedDates: val => moment(val).day() !== 0 && moment(val).day() !== 6,
    async save() {
      await this.$refs.form.validate();
      if (!this.valid) return;

      const method = this.client.id ? "put" : "post";
      const url = this.client.id
        ? `${baseApiUrl}/client/${this.client.id}`
        : `${baseApiUrl}/clients`;
      axios[method](url, this.client)
        .then(() => {
          this.$router.push("/clientes");
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data);
          }
        });
    },
    async getResales() {
      axios
        .get(`${baseApiUrl}/resales`)
        .then((resales) => {
          this.items = resales.data;
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data);
          }
        });
    },
    reset() {
      this.$refs.form.reset();
      this.disabled = true;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    if (this.$route.params.id) {
      const url = `${baseApiUrl}/client/${this.$route.params.id}`;
      axios.get(url).then((res) => {
        this.client = res.data;
        this.disabled = false;
      });
    }
    this.getResales();
  },
};
</script>

<style>
.input-uppercase input {
  text-transform: uppercase;
}
</style>