<template>
  <v-navigation-drawer v-model="drawer" app dark>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> RFS ADMIN </v-list-item-title>
        <v-list-item-subtitle> RFS TECNOLOGIA </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav flat>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.to" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-menu offset-x rounded="0" transition="slide-x-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" :key="3">
              <v-list-item-icon>
                <v-icon>mdi-key-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Chave</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item link to="/chave/gerar" @click="selectKey">
              <v-list-item-title>Preço Rapido</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/chave-light/gerar" @click="selectKey">
              <v-list-item-title>Light PDV</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/chave-clearbank/gerar" @click="selectKey">
              <v-list-item-title>Clearbank</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/chave-big/gerar" @click="selectKey">
              <v-list-item-title>BIG PDV</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/chave/listar" @click="selectKey">
              <v-list-item-title>Relatorio</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Menu",
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit("toggleMenu", value);
      },
    },
  },
  data: () => ({
    items: [
      { title: "Inicio", icon: "mdi-home", to: "/" },
      { title: "Usuario", icon: "mdi-account", to: "/usuarios" },
      { title: "Revenda", icon: "mdi-account-tie", to: "/revendas" },
      { title: "Produtos", icon: "mdi-barcode", to: "/produtos" },
      { title: "Cliente", icon: "mdi-store", to: "/clientes" },
    ],
    menu: false,
    selectedItem: 0,
  }),
  methods: {
    selectKey() {
      this.selectedItem = 5;
    },
  },
};
</script>

<style>
</style>